//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex";
import checkCircle from "@/assets/check-circle-primary.png";
import { queryGeneticDataById } from "@debionetwork/polkadot-provider";
export default {
  name: "SuccessDialog",
  data: () => ({
    checkCircle
  }),
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "Title"
    },
    orderId: {
      type: [String, Number]
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api
    })
  },
  methods: {
    ...mapMutations({
      setSelectedGeneticData: "geneticData/SET_SELECTED_DATA"
    }),
    closeDialog() {
      this.$emit("close");
    },
    toGeneticDataList() {
      this.$router.push({
        name: "customer-genetic-data"
      });
    },
    async toReqAnalysis() {
      const geneticData = await queryGeneticDataById(this.api, this.orderId);
      this.setSelectedGeneticData(geneticData);
      this.$router.push({
        name: "customer-request-analysis-service"
      });
    }
  }
};
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkCircle from "@/assets/check-circle-primary.png";
import SpinnerLoader from "@bit/joshk.vue-spinners-css.spinner-loader";
import { mapState } from "vuex";
export default {
  name: "UploadingDialog",
  data: () => ({
    progress: "0%",
    checkCircle,
    urlUpload: "https://docs.debio.network/complete-guidelines/user-guideline/upload-and-encrypt-data",
    urlDownload: "https://docs.debio.network/complete-guidelines/genetic-analyst-guideline/download-and-decrypt-data"
  }),
  components: {
    SpinnerLoader
  },
  props: {
    show: Boolean,
    type: String,
    url: String,
    file: String,
    // The current file being uploaded
    isFailed: Boolean,
    // Indicates if the current chunk upload has failed
    totalChunks: Number,
    // Total number of chunks
    currentChunkIndex: Number // Index of the current chunk being uploaded (0-based)
  },

  watch: {
    loadingProgress() {
      this.progress = "".concat(this.loadingProgress || 0, "%");
    }
  },
  computed: {
    ...mapState({
      loadingProgress: state => state.geneticData.loadingProgress
    }),
    renderUrlDownload() {
      return this.type === "download" ? this.urlDownload : this.urlUpload;
    }
  },
  methods: {
    onClose() {
      // Implement close functionality as needed
    },
    retryUpload() {
      // Emit an event to the parent component to handle the retry for the failed chunk
      this.$emit("retry-upload");
    }
  }
};